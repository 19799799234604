import auth from '@/middleware/auth.js'

export default [
  {
    path: ''
    ,component: () => import('@/layouts/Constelacion')
    ,children: [
      {
        path: '/metricas'
        ,name: 'metricas'
        ,component: () => import('@/apps/metricas/Dashboard')
        ,meta: {
          title: 'Metricas'
          ,middleware: auth
        }
      },
      {
        path: '/metricas/solicitudes'
        ,name: 'metricas_solicitudes'
        ,component: () => import('@/apps/metricas/pages/Solicitud')
        ,meta: {
          title: 'Solicitudes - Metricas'
          ,middleware: auth
        }
      },
      {
        path: '/metricas/etapas'
        ,name: 'metricas_etapas'
        ,component: () => import('@/apps/metricas/pages/Etapa')
        ,meta: {
          title: 'Etapas - Metricas'
          ,middleware: auth
        }
      },
      {
        path: '/metricas/catalogos'
        ,name: 'metricas_catalogos'
        ,component: () => import('@/apps/metricas/pages/Catalogo')
        ,meta: {
          title: 'Catálogos - Metricas'
          ,middleware: auth
        }
      },
      {
        path: '/metricas/configuración'
        ,name: 'metricas_configuracion'
        ,component: () => import('@/apps/metricas/pages/Configuracion')
        ,meta: {
          title: 'Configuración - Metricas'
          ,middleware: auth
        }
      }
    ]
  }
]